import React, { useEffect } from "react";
import jwtDecode from "jwt-decode";

import {
  getCognitoLoginUrl,
  verifyCognitoCode,
  refreshCognitoToken,
  CognitoToken,
  getCognitoLogoutUrl
} from "./cognito";

const TOKEN_STORAGE_KEY = "tokens";

type UserServiceWindow = Pick<Window, "location">;

export class UserService {
  private window: UserServiceWindow;
  private _tokens: null | Readonly<CognitoToken>;
  private bootPromise: Promise<void> | undefined;
  private booted: boolean;
  private bootError?: Error;

  constructor(window: UserServiceWindow) {
    this.window = window;
    this.booted = false;
    this._tokens = readCognitoTokens();
  }

  private get tokens() {
    return this._tokens;
  }

  private set tokens(value: CognitoToken | null) {
    this._tokens = value;
    writeCognitoTokens(value);
  }

  public boot() {
    this.bootPromise =
      this.bootPromise ||
      this.bootstrap()
        .catch(error => (this.bootError = error))
        .finally(() => (this.booted = true));
    return this.bootPromise;
  }

  public logout() {
    this.tokens = null;
    this.window.location.href = getCognitoLogoutUrl();
  }

  public async getToken(refresh?: boolean) {
    await this.bootPromise;
    return this.tokens!.id_token;
  }

  public suspendForBoot() {
    if (!this.booted) {
      throw this.boot();
    }
    return this.bootError;
  }

  private async bootstrap() {
    if (this.window.location.pathname === "/logout") {
      this.tokens = null;
      history.replaceState({}, "KBC", "/");
      throw new Error("Logged Out");
    }

    const code = this.getCallbackToken();

    if (code) {
      history.replaceState({}, "KBC", "/");
      this.tokens = await this.getTokensWithCode(code);
    }

    if (this.tokens) {
      if (!areTokensActive(this.tokens)) {
        const token = this.tokens.refresh_token;
        console.log("refresh token", token);
        this.tokens = await this.getTokensWithRefreshToken(token);
      }
      return;
    }

    this.window.location.href = getCognitoLoginUrl();
    await delay(2000);
  }

  private getCallbackToken(): string | null {
    if (this.window.location.pathname === "/callback") {
      const search = new URLSearchParams(this.window.location.search);
      return search.get("code");
    }
    return null;
  }

  private getTokensWithCode(code: string) {
    return verifyCognitoCode(code).catch(() => null);
  }

  private getTokensWithRefreshToken(refreshToken: string) {
    return refreshCognitoToken(refreshToken).catch(() => null);
  }
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function readCognitoTokens(): CognitoToken | null {
  try {
    const raw = localStorage.getItem(TOKEN_STORAGE_KEY);
    return raw ? JSON.parse(raw) : null;
  } catch (error) {
    console.warn("Error reading local storage:", error);
    return null;
  }
}

function writeCognitoTokens(value: CognitoToken | null) {
  if (value) {
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(value));
  } else {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  }
}

function decodeTokenForExpirations(token: string): DecodedToken {
  try {
    return jwtDecode(token);
  } catch {
    return { exp: 0 };
  }
}

function areTokensActive(tokens: CognitoToken) {
  const now = Date.now().valueOf() / 1000;
  const decoded = decodeTokenForExpirations(tokens.id_token);
  return (
    (typeof decoded.exp === "undefined" || now < decoded.exp) &&
    (typeof decoded.nbf === "undefined" || now > decoded.nbf)
  );
}

interface DecodedToken {
  exp?: number;
  nbf?: number;
}

const userServiceContext = React.createContext(new UserService(window));

export function useUserService() {
  return React.useContext(userServiceContext);
}

export const UserServiceProvider: React.FC<{}> = ({ children }) => {
  const [service] = React.useState(() => new UserService(window));

  return (
    <userServiceContext.Provider value={service}>
      {children}
    </userServiceContext.Provider>
  );
};

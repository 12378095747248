import React, {
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  Ref,
  useRef
} from "react";
import { Button } from "../spectre/Button";
import { Icon, NavigationIcon } from "../spectre/Icon";

import styles from "./Calendar.module.css";

import {
  eachDayOfInterval,
  lastDayOfMonth,
  setDay,
  isToday,
  isSameDay,
  isWithinInterval,
  isAfter,
  set as dateSet,
  add as dateAdd
} from "date-fns";
import classNames from "classnames";
import { useClickOutside } from "../hooks/useClickOutside";

export type CalendarValue = null | Date | { start: Date; end: Date };

export interface CalendarProps {
  value: CalendarValue;
  onChange: (value: CalendarValue) => void;

  initialMonth?: number;
  initialYear?: number;
  className?: string;
}

export const Calendar = React.forwardRef(
  (props: CalendarProps, ref: Ref<HTMLDivElement>) => {
    const { value, onChange } = props;
    const rangeStart = !value
      ? undefined
      : value instanceof Date
      ? value
      : value.start;

    const rangeEnd = !value || value instanceof Date ? undefined : value.end;

    const [date, setDate] = useState(() => rangeStart || new Date());

    const [hoverDate, setHoverDate] = useState<undefined | Date>();

    const handleDateClick = (date: Date) => {
      if (rangeStart && !rangeEnd) {
        onChange({ start: rangeStart, end: date });
      } else {
        console.log("here");
        onChange(date);
      }
    };

    return (
      <div
        ref={ref}
        className={classNames("calendar", styles.calendar, props.className)}
      >
        <CalendarNavbar date={date} onChange={setDate} />
        <div className="calendar-container">
          <CalendarHeader />
          <CalendarBody
            year={date.getFullYear()}
            month={date.getMonth()}
            rangeStart={rangeStart}
            rangeEnd={rangeStart && (rangeEnd || hoverDate)}
            onDateHover={!!rangeStart && !rangeEnd ? setHoverDate : undefined}
            onDateClick={handleDateClick}
          />
        </div>
      </div>
    );
  }
);

export interface CalendarNavbarProps {
  date: Date;
  onChange: Dispatch<SetStateAction<Date>>;
}

export const CalendarNavbar: React.FC<CalendarNavbarProps> = props => {
  const { date, onChange } = props;
  const month = date.getMonth();
  const year = date.getFullYear();

  const nextMonth = () => onChange(date => dateAdd(date, { months: 1 }));
  const previousMonth = () => onChange(date => dateAdd(date, { months: -1 }));
  const setYear = (year: number) => onChange(date => dateSet(date, { year }));
  const setMonth = (month: number) =>
    onChange(date => dateSet(date, { month }));

  return (
    <div className="calendar-nav navbar">
      <Button
        buttonStyle="link"
        type="button"
        buttonSize="large"
        onClick={previousMonth}
      >
        <Icon icon={NavigationIcon.ArrowLeft} />
      </Button>
      <div className="navbar-primary">
        <MonthPicker month={month} onChange={setMonth} />
        <YearPicker year={year} onChange={setYear} />
      </div>
      <Button
        buttonStyle="link"
        type="button"
        buttonSize="large"
        onClick={nextMonth}
      >
        <Icon icon={NavigationIcon.ArrowRight} />
      </Button>
    </div>
  );
};

export interface CalendarHeaderProps {}

export const CalendarHeader: React.FC<CalendarHeaderProps> = props => {
  const date = new Date();
  date.setDate(date.getDate() - date.getDay());
  const abbreviations: React.ReactNode[] = [];

  do {
    const abbr = date.toLocaleString("default", { weekday: "short" });
    abbreviations.push(
      <div key={date.getDay()} className="calendar-date">
        {abbr}
      </div>
    );
    date.setDate(date.getDate() + 1);
  } while (date.getDay() > 0);

  return <div className="calendar-header">{abbreviations}</div>;
};

export interface CalendarBodyProps {
  month: number;
  year: number;
  rangeStart?: Date;
  rangeEnd?: Date;
  onDateClick?: (date: Date) => void;
  onDateHover?: (date: Date) => void;
}

export const CalendarBody: React.FC<CalendarBodyProps> = props => {
  const { month, year, onDateClick, onDateHover, rangeStart, rangeEnd } = props;
  const dates = useCalendarDates(year, month);

  const [lower, upper, interval] =
    !rangeStart || !rangeEnd
      ? [undefined, undefined, undefined]
      : isAfter(rangeStart, rangeEnd)
      ? [rangeEnd, rangeStart, { start: rangeEnd, end: rangeStart }]
      : [rangeStart, rangeEnd, { start: rangeStart, end: rangeEnd }];

  const items = dates.map(date => {
    const divClasses = classNames("calendar-date", {
      "prev-month": date.getMonth() < month,
      "next-month": date.getMonth() > month,
      "calendar-range": interval && isWithinInterval(date, interval),
      "range-start": lower && isSameDay(lower, date),
      "range-end": upper && isSameDay(upper, date)
    });

    const buttonClasses = classNames("date-item", {
      "date-today": isToday(date)
    });

    const handleDateClick =
      onDateClick &&
      (() => {
        onDateClick(date);
      });

    const handleOnDateHover =
      onDateHover &&
      (() => {
        onDateHover(date);
      });

    return (
      <div
        onMouseEnter={handleOnDateHover}
        key={date.toISOString()}
        className={divClasses}
      >
        <button className={buttonClasses} onClick={handleDateClick}>
          {date.getDate()}
        </button>
      </div>
    );
  });

  return <div className="calendar-body">{items}</div>;
};

function useCalendarDates(year: number, month: number): Date[] {
  const dayOne = new Date(year, month, 1);
  const startDate = setDay(dayOne, 0);
  const endDate = setDay(lastDayOfMonth(dayOne), 6);
  return eachDayOfInterval({ start: startDate, end: endDate });
}

export interface ValuePicker {
  value: number;
  options: { value: number; label: string }[];
  onChange?: (value: number) => void;
}

export const ValuePicker: React.FC<ValuePicker> = props => {
  const { value, options, onChange } = props;
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const labels: Record<number, string> = {};

  useClickOutside(ref.current, () => setActive(false), [setActive]);

  const items = options.map(option => {
    labels[option.value] = option.label;

    const handleOnClick = (event: React.MouseEvent) => {
      event.preventDefault();
      setActive(false);
      if (onChange) {
        onChange(option.value);
      }
    };

    return (
      <li className="menu-item" key={option.value} onClick={handleOnClick}>
        <a href="#">{option.label}</a>
      </li>
    );
  });

  const className = classNames("dropdown", {
    active: active
  });

  return (
    <div className={className} ref={ref}>
      <Button
        type="button"
        buttonStyle="link"
        onClick={() => setActive(a => !a)}
      >
        {labels[value]}
      </Button>
      <ul className="menu">{items}</ul>
    </div>
  );
};

export interface MonthPickerProps {
  month: number;
  onChange?: (month: number) => void;
}

export const MonthPicker: React.FC<MonthPickerProps> = props => {
  const monthOptions = useMemo(
    () =>
      monthNames().map((name, index) => ({
        value: index,
        label: name
      })),
    []
  );

  return (
    <ValuePicker
      value={props.month}
      onChange={props.onChange}
      options={monthOptions}
    />
  );
};

export interface YearPickerProps {
  year: number;
  onChange?: (year: number) => void;
}

export const YearPicker: React.FC<YearPickerProps> = props => {
  const { year, onChange } = props;

  const options = useMemo(
    () =>
      Array.from(Array(10)).map((_, i) => ({
        value: year + i - 5,
        label: String(year + i - 5)
      })),
    [year]
  );

  return <ValuePicker value={year} onChange={onChange} options={options} />;
};

function monthNames() {
  return Array.from(Array(12)).map((_, i) => monthName(i));
}

function monthName(month: number) {
  return new Date(2012, month, 1).toLocaleString("default", { month: "long" });
}

const COGNITO_HOST = "https://login.kbc-ministries.adharris.io";
const COGNITO_CLIENT_ID = "47dr6rjegt0kk9fei9g6o9fuou";

function getCallbackUrl() {
  const url = new URL(window.location.href);
  url.pathname = "/callback";
  console.log(url.href);
  return url.href;
}

function getLogoutUrl() {
  const url = new URL(window.location.href);
  url.pathname = "/logout";
  return url.href;
}

export interface CognitoToken {
  id_token: string;
  access_token: string;
  refresh_token: string;
  token_type: "Bearer";

  expires_in: number;
}

export function getCognitoLoginUrl() {
  const url = new URL(`${COGNITO_HOST}/login`);
  url.searchParams.set("response_type", "code");
  url.searchParams.set("client_id", COGNITO_CLIENT_ID);
  url.searchParams.set("redirect_uri", getCallbackUrl());
  return url.href;
}

export function getCognitoLogoutUrl() {
  const url = new URL(`${COGNITO_HOST}/logout`);
  url.searchParams.set("client_id", COGNITO_CLIENT_ID);
  url.searchParams.set("logout_uri", getLogoutUrl());
  return url.href;
}

export async function verifyCognitoCode(code: string): Promise<CognitoToken> {
  const response = await fetch(`${COGNITO_HOST}/oauth2/token`, {
    method: "POST",
    body: new URLSearchParams({
      grant_type: "authorization_code",
      client_id: COGNITO_CLIENT_ID,
      code: code,
      redirect_uri: getCallbackUrl()
    })
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}

export async function refreshCognitoToken(
  refreshToken: string
): Promise<CognitoToken> {
  const response = await fetch(`${COGNITO_HOST}/oauth2/token`, {
    method: "POST",
    body: new URLSearchParams({
      grant_type: "refresh_token",
      client_id: COGNITO_CLIENT_ID,
      refresh_token: refreshToken
    })
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}

import React from "react";
import { Button, ButtonProps } from "./Button";

export interface EmptyAction {
  type: ButtonProps["buttonStyle"];
  text: string;
  onClick: () => void;
}

export interface EmptyProps {
  title: string;
  subtitle?: string;
  actions: EmptyAction[];
}

export const Empty: React.FC<EmptyProps> = props => (
  <div className="empty">
    {/* <div className="empty-icon">
      <i className="icon icon-people"></i>
    </div> */}
    <p className="empty-title h5">{props.title}</p>
    {props.subtitle && <p className="empty-subtitle">{props.subtitle}</p>}
    {props.actions.map((action, index) => (
      <div className="empty-action">
        <Button
          type="button"
          buttonStyle={action.type}
          onClick={action.onClick}
        >
          {action.text}
        </Button>
      </div>
    ))}
  </div>
);

import React, { CSSProperties } from "react";
import { useTransition, animated } from "react-spring";

export interface FoldInProps {
  show: boolean;
  className?: string;
}

const transform = (t: number, r: number) =>
  `perspective(1000px) translateY(${t}px) rotateX(${r}deg)`;

interface AnimatedProps {
  opacity: number;
  tr: [number, number];
}

export const FoldIn: React.FC<FoldInProps> = props => {
  const { children, show, className } = props;

  const transition = useTransition<boolean, AnimatedProps>(show, null, {
    from: { opacity: 0, tr: [8, 10] },
    enter: { opacity: 1, tr: [0, 0] },
    leave: { opacity: 0, tr: [8, 10] }
  } as any);

  const items = transition.map(({ item, key, props: { tr, ...rest } }) => {
    return (
      item && (
        <animated.div
          key={key}
          className={className}
          style={{
            transform: tr.interpolate(transform as any),
            ...rest
          }}
        >
          {children}
        </animated.div>
      )
    );
  });

  return <>{items}</>;
};

import React, { Suspense } from "react";

import { BrowserRouter, Route, Link } from "react-router-dom";
import { CallbackRoute } from "./Callback";
import { Container, Columns, Column } from "../spectre/Grid";
import { Menu } from "../components/MenuBar";
import { Home } from "./Home";
import { Member } from "./Member";
import { Members } from "./Members";
import { Loading } from "../spectre/Loading";
import { Contribution } from "./Contributions";

export const Router = () => (
  <Container size="x-large">
    <BrowserRouter>
      <Columns>
        <Column>
          <Menu
            title="KBC Ministries"
            items={[
              { path: "/", label: "Home", exact: true },
              { path: "/members", label: "Members" },
              { path: "/contributions", label: "Contributions" },
              { path: "/reports", label: "Reports" }
            ]}
          />
          <Suspense fallback={<Loading />}>
            <Route path="/" exact={true} component={Home} />
            <Route path="/members" exact={true} component={Members} />
            <Route path="/members/:id" exact={true} component={Member} />
            <Route
              path="/contributions"
              exact={true}
              component={Contribution}
            />
            <Route path="/callback">
              <CallbackRoute />
            </Route>
          </Suspense>
        </Column>
      </Columns>
    </BrowserRouter>
  </Container>
);

import React from "react";
import { Tabs, NavTab, TabAction, TabItem } from "../spectre/Tabs";
import { LogoutButton } from "./LogoutButton";
import { LoadingLink } from "./LoadingLink";

export interface MenuItem {
  path: string;
  label: string;
  exact?: boolean;
}

export interface MenuProps {
  title: string;
  items: MenuItem[];
}

export const Menu: React.FC<MenuProps> = props => {
  const tabs = props.items.map(item => (
    <NavTab
      key={item.path}
      to={item.path}
      text={item.label}
      exact={item.exact}
    />
  ));

  return (
    <Tabs>
      <TabItem>
        <LoadingLink to="/" className="text-bold text-primary">
          {props.title}
        </LoadingLink>
      </TabItem>
      {tabs}
      <TabAction>
        <LogoutButton />
      </TabAction>
    </Tabs>
  );
};

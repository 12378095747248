import { useCallback, useEffect } from "react";

export function useClickOutside(
  element: HTMLElement | null,
  callback: (event: MouseEvent) => void,
  callbackInputs?: unknown[]
) {
  const handler = useCallback(
    (event: MouseEvent) => {
      console.log(event.target, element);
      if (
        !event.target ||
        (element && !element.contains(event.target as Node))
      ) {
        callback(event);
      }
    },
    [element, ...(callbackInputs || [])]
  );

  useEffect(() => {
    document.addEventListener("click", handler);
    return () => document.removeEventListener("click", handler);
  }, [handler]);
}

import React from "react";
import classNames from "classnames";

export interface LoadingProps {
  large?: boolean;
  className?: string;
}

export const Loading: React.FC<LoadingProps> = props => {
  const className = classNames(props.className, "loading", {
    "loading-lg": props.large
  });
  return <div className={className} />;
};

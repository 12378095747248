import React, { HTMLProps, forwardRef, Ref } from "react";
import { IconType, Icon } from "./Icon";
import classNames from "classnames";

export interface InputProps extends HTMLProps<HTMLInputElement> {}

export const Input = forwardRef(
  (props: InputProps, ref: Ref<HTMLInputElement>) => {
    const { className, ...rest } = props;
    const inputClasses = classNames("form-input", className);
    return <input className={inputClasses} {...rest} ref={ref} />;
  }
);

export interface InputGroupProps {
  label?: string;
  labelFor?: string;
  icon?: IconType;
  loading?: boolean;
  iconLocation?: "left" | "right";
  className?: string;
}

export const InputGroup: React.FC<InputGroupProps> = props => {
  const className = classNames("input-group", props.className {
    "has-icon-left": props.icon && props.iconLocation !== "right",
    "has-icon-right":
      (props.icon && props.iconLocation === "right") || props.loading
  });

  return (
    <div className={className}>
      {props.label && (
        <label className="form-label" htmlFor={props.labelFor}>
          {props.label}
        </label>
      )}
      {props.icon && <Icon icon={props.icon} className="form-icon" />}
      {props.children}
      {props.loading && <i className="form-icon loading" />}
    </div>
  );
};

import { useLocation, useHistory } from "react-router";
import {
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useTransition
} from "react";

import { parse, format, isValid } from "date-fns";

type SetState<T> = Dispatch<SetStateAction<T>>;
type SetStateHook<T> = [T, SetState<T>];
type SingleValueHook = SetStateHook<string>;
type MultiValueHook<K extends string> = SetStateHook<Record<K, string | null>>;

interface SetSearchParams {
  (name: string, value: string | null): void;
  (params: Record<string, string | null>): void;
}

type UseURLSearchParamsHook = [URLSearchParams, SetSearchParams, boolean];

export function useURLSearchParams(): UseURLSearchParamsHook {
  const location = useLocation();
  const history = useHistory();

  const [startTransition, pending] = useTransition({ timeoutMs: 2000 });
  const search = useMemo(() => new URLSearchParams(location.search), [
    location.search
  ]);

  const setSearch: SetSearchParams = useCallback(
    (name: string | Record<string, string | null>, value?: string | null) => {
      const nextSearch = new URLSearchParams(search);
      const toSet: [string, string | null][] =
        typeof name === "string"
          ? [[name, value || null]]
          : Object.entries(name);
      for (const [key, value] of toSet) {
        if (!value) {
          nextSearch.delete(key);
        } else {
          nextSearch.set(key, value);
        }
      }
      const query = nextSearch.toString();

      if (query === search.toString()) {
        return;
      }

      const path = query ? `${location.pathname}?${query}` : location.pathname;

      startTransition(() => {
        history.replace(path);
      });
    },
    [search, history]
  );

  return [search, setSearch, pending];
}

export function useSearchParam(name: string) {
  const [search, setSearch, pending] = useURLSearchParams();
  const value = search.get(name);
  const setValue: SetState<null | string> = useCallback(
    nextValue => {
      if (typeof nextValue === "function") {
        setSearch(name, nextValue(value));
      } else {
        setSearch(name, nextValue);
      }
    },
    [name, value, setSearch]
  );

  return [search.get(name), setValue, pending] as const;
}

export function useDateSearchParam(name: string) {
  const [search, setSearch, pending] = useURLSearchParams();
  const str = search.get(name);
  const date = useMemo(() => parseDateOrNull(str), [str]);

  const setValue: SetState<null | Date> = useCallback(
    nextValue => {
      if (typeof nextValue === "function") {
        setSearch(name, formatDateOrNull(nextValue(date)));
      } else {
        setSearch(name, formatDateOrNull(nextValue));
      }
    },
    [name, date, setSearch]
  );

  return [date, setValue, pending] as const;
}

function parseDateOrNull(value: string | null) {
  if (!value) return null;
  const date = parse(value, "yyyy-MM-dd", new Date());
  return isValid(date) ? date : null;
}

function formatDateOrNull(value: Date | null) {
  if (!value) return value;
  return isValid(value) ? format(value, "yyyy-MM-dd") : null;
}

import React, {
  useState,
  useEffect,
  useMemo,
  useTransition,
  Suspense,
  useDeferredValue
} from "react";
import { useApiService } from "../utils/ApiService";
import { useHistory, useLocation } from "react-router-dom";
import { Column, Columns } from "../spectre/Grid";
import { Loading } from "../spectre/Loading";
import { LoadingLink } from "../components/LoadingLink";
import { InputGroup } from "../spectre/Input";
import { Member } from "../../models/Member";
import { useSearchParam } from "../hooks/query-string";
import { BufferedSearch } from "../components/BufferedSearch";
import { DynamoSerialized } from "../../models/DynamoRecord";
import { Currency } from "../components/Currency";
import { Page } from "../components/Page";

const CURRENT_YEAR = new Date().getFullYear();

export const Members: React.FC<{}> = props => {
  const [search, setSearch, searching] = useSearchParam("search");
  const deferred = useDeferredValue(search || "", { timeoutMs: 2000 });
  const searchAction = (
    <BufferedSearch
      value={search || ""}
      onChange={setSearch}
      placeholder="Search Members"
      pending={searching}
      holdEmit={searching}
      delay={1000}
    />
  );

  return (
    <Page title="Members" actions={searchAction}>
      <MemberList search={deferred} />
    </Page>
  );
};

export interface MemberListProps {
  search?: string;
}

export const MemberList: React.FC<MemberListProps> = props => {
  // const [search] = useSearchParam("search");
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th>{CURRENT_YEAR} YTD</th>
          <th>Last Total</th>
          <th>Lifetime</th>
        </tr>
      </thead>
      <tbody>
        <Suspense fallback={<LoadingRow colspan={5} />}>
          <MemberRows search={props.search || ""} />
        </Suspense>
      </tbody>
    </table>
  );
};

interface LoadingRowProps {
  colspan: number;
}

const LoadingRow: React.FC<LoadingRowProps> = props => {
  return (
    <tr>
      <td colSpan={props.colspan}>
        <Loading />
      </td>
    </tr>
  );
};

interface MemberRowsProps {
  search: string;
}

const MemberRows: React.FC<MemberRowsProps> = props => {
  const api = useApiService();
  const members = api.members.getMemberList(props.search);
  const year = 2019;

  const rows = members.map(member => (
    <MemberRow key={member.id} member={member} />
  ));

  return <>{rows}</>;
};

interface MemberRowProps {
  member: Member;
}

const MemberRow: React.FC<MemberRowProps> = props => {
  const { member } = props;
  const year = new Date().getFullYear();
  const totals = member.yearlyTotals;
  const lastYear = Math.max(
    ...Object.keys(totals)
      .map(Number)
      .filter(y => y < year)
  );
  const lastAmount = totals[lastYear];
  const lifetime = Object.values(totals).reduce((a, b) => a + b, 0);
  const ytd = totals[year];

  return (
    <tr>
      <td>
        <MemberLink member={member} />
      </td>
      <td>
        <Address {...member.mainAddress} />
      </td>
      <td>{ytd && <Currency value={ytd} />}</td>
      <td>
        {lastYear && lastAmount && (
          <>
            <Currency value={lastAmount} />{" "}
            <span className="text-gray">{lastYear}</span>
          </>
        )}
      </td>
      <td>{!!lifetime && <Currency value={lifetime} />}</td>
    </tr>
  );
};

interface AddressProps {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

const Address: React.FC<AddressProps> = props => {
  const { address1, address2, city, state, zip } = props;
  return (
    <address>
      {address1} {address2}, {city} {state}
    </address>
  );
};

interface MemberLinkProps {
  member: Member;
}

const MemberLink: React.FC<MemberLinkProps> = props => (
  <LoadingLink to={`/members/${props.member.id}`}>
    {props.member.reportName} {props.member.lastName}
  </LoadingLink>
);

import { ApiService } from "./ApiService";
import { Member } from "../../models/Member";
import { APICache } from "./APICache";

export class MemberService {
  private members: MemberCache;
  private memberList: MemberListCache;

  constructor(api: ApiService) {
    this.members = new MemberCache(api);
    this.memberList = new MemberListCache(api);
  }

  getMember(id: string): Member {
    return this.members.get(id);
  }

  getMemberList(search: string): Member[] {
    return this.memberList.get(search);
  }
}

class MemberCache extends APICache<Member, string> {
  getUrl(id: string) {
    return `/members/${id}`;
  }
}

class MemberListCache extends APICache<Member[], string> {
  getUrl(search: string) {
    return ["/members", { search }] as const;
  }

  processResults(results: any) {
    return results.items;
  }
}

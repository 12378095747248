import React, { useRef, useEffect } from "react";
import { Columns, Column } from "../spectre/Grid";

import styles from "./Page.module.css";

console.log("styles", styles);

export interface PageProps {
  title: string;
  actions?: React.ReactNode;
}

function useDocumentTitle(title: string) {
  const baseTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${title} | ${baseTitle.current}`;
    return () => {
      document.title = baseTitle.current;
    };
  });
}

export const Page: React.FC<PageProps> = props => {
  const { title, actions, children } = props;
  useDocumentTitle(title);
  return (
    <div className={styles.page}>
      <Columns className={styles.header}>
        <Column size={8}>
          <h1>{title}</h1>
        </Column>
        <Column size={4}>{actions}</Column>
      </Columns>
      {children}
    </div>
  );
};

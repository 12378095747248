import React from "react";
import { Button } from "../spectre/Button";
import { Empty } from "../spectre/Empty";
import { Container, Columns, Column } from "../spectre/Grid";

export interface BootErrorProps {
  error: Error;
}

export const BootError: React.FC<BootErrorProps> = props => {
  console.log(props.error.message, props.error.message === "Logged Out");

  if (props.error.message === "Logged Out") {
    return (
      <div className="m-2">
        <Container size="small">
          <Columns>
            <Column>
              <div className="card">
                <Empty
                  title="Logged out"
                  subtitle="Please log in again to continue"
                  actions={[
                    {
                      text: "Log in",
                      type: "primary",
                      onClick: () => (window.location.href = "/")
                    }
                  ]}
                />
              </div>
            </Column>
          </Columns>
        </Container>
      </div>
    );
  }

  return <div>{String(props.error)}</div>;
};

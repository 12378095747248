import React from "react";
import { Columns, Column } from "../spectre/Grid";
import { Page } from "../components/Page";
import { Calendar } from "../components/Calendar";
import { RangePicker } from "../components/RangePicker";
import { useApiService } from "../utils/ApiService";
import { useURLSearchParams } from "../hooks/query-string";

export interface ContributionProps {}

export const Contribution: React.FC<ContributionProps> = props => {
  const actions = <RangePicker />;
  const api = useApiService().contributions;
  const [queryString] = useURLSearchParams();
  return (
    <Page title="Contributions" actions={actions}>
      <pre>
        {JSON.stringify(
          api.getContributions({
            start: queryString.get("start"),
            end: queryString.get("end")
          }),
          null,
          2
        )}
      </pre>
    </Page>
  );
};

import React from "react";
import classNames from "classnames";

export enum NavigationIcon {
  ArrowUp = "arrow-up",
  ArrowRight = "arrow-right",
  ArrowLeft = "arrow-left",
  ArrowDown = "arrow-down",
  Upward = "upward",
  Downward = "downward",
  Forward = "forward",
  Backward = "back", //Correct and inconsistent.
  Caret = "caret",
  Menu = "menu",
  Apps = "apps",
  MoreHorizontal = "more-horiz",
  MoreVertical = "more-vert"
}

export enum ActionIcon {
  ResizeHorizontal = "resize-horiz",
  ResizeVertical = "resize-vert",
  Plus = "plus",
  Minus = "minus",
  Cross = "cross",
  Check = "check",
  Stop = "stop",
  Shutdown = "shutdown",
  Refresh = "refresh",
  Search = "search",
  Flag = "flag",
  Bookmark = "bookmark",
  Edit = "edit",
  Delete = "delete",
  Share = "share",
  Download = "download",
  Upload = "upload",
  Copy = "copy"
}

export enum ObjectIcon {
  Mail = "mail",
  People = "people",
  Message = "message",
  Photo = "photo",
  Time = "time",
  Location = "location",
  Link = "link",
  Emoji = "emoji"
}

export type IconType = NavigationIcon | ObjectIcon | ActionIcon;

export interface IconProps {
  icon: IconType;
  className?: string;
}

export const Icon: React.FC<IconProps> = props => {
  const className = classNames(props.className, "icon", "icon-" + props.icon);
  return <i className={className} />;
};

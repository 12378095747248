import React from "react";
import classNames from "classnames";

const SIZE_MAP = {
  "x-small": "xs",
  small: "sm",
  medium: "md",
  large: "lg",
  "x-large": "xl"
};

type Size = keyof typeof SIZE_MAP;

function sizeClass(prefix: string, size?: Size) {
  return size ? `${prefix}-${SIZE_MAP[size]}` : "";
}

export interface ContainerProps {
  size?: Size;
}

export const Container: React.FC<ContainerProps> = ({ size, children }) => {
  const className = classNames("container", sizeClass("grid", size));
  return <div className={className}>{children}</div>;
};

export interface ColumnsProps extends React.HTMLProps<HTMLDivElement> {}

export const Columns: React.FC<ColumnsProps> = (props) => {
  const {className, children, ...rest} = props;
  const newClasses = classNames(className, "columns");
  return <div className={newClasses} {...rest}>{children}</div>
};

export interface ColumnProps {
  size?: number;
}

export const Column: React.FC<ColumnProps> = props => {
  const className = classNames("column", props.size && 'col-' + props.size));
  return <div className={className}>{props.children}</div>;
};

import React, { useState, useEffect } from "react";
import { useUserService } from "./UserService";
import { MemberService } from "./MemberService";
import { ContributionService } from "./ContributionService";

interface TokenProvider {
  getToken(refresh?: boolean): Promise<string>;
}

export class ApiService {
  private tokenProvider: TokenProvider;
  private baseUrl: string;

  public readonly members: MemberService;
  public readonly contributions: ContributionService;

  constructor(baseUrl: string, tokenProvider: TokenProvider) {
    this.baseUrl = baseUrl;
    this.tokenProvider = tokenProvider;
    this.members = new MemberService(this);
    this.contributions = new ContributionService(this);
  }

  get(path: string, params?: URLSearchParams | Record<string, string>) {
    const url = this.pathToUrl(path, params);
    return this.fetch(url);
  }

  post(path: string, body: unknown) {
    const bodyJson = JSON.stringify(body);
    const url = this.pathToUrl(path);
    return this.fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: bodyJson
    });
  }

  private pathToUrl(
    path: string,
    params?: URLSearchParams | Record<string, string>
  ) {
    if (!params || !(params instanceof URLSearchParams)) {
      params = new URLSearchParams(params);
    }

    const url = new URL(this.baseUrl);
    url.pathname = path;
    url.search = params.toString();
    return url.href;
  }

  private async fetch(input: RequestInfo, init?: RequestInit) {
    const { headers, ...newInit } = init || {};
    const newHeaders = new Headers(headers);

    const token = await this.tokenProvider.getToken();
    newHeaders.set("Authorization", `Bearer ${token}`);

    const result = await fetch(input, { ...newInit, headers: newHeaders });

    if (result.ok) {
      return result.json();
    }

    throw result;
  }
}

const ApiServiceContext = React.createContext(
  new ApiService("/", {
    getToken: async () => "none"
  })
);

export function useApiService() {
  return React.useContext(ApiServiceContext);
}

export interface ApiServiceProviderProps {
  baseUrl?: string;
  tokenProvider?: TokenProvider;
}

export const ApiServiceProvider: React.FC<ApiServiceProviderProps> = props => {
  const userService = useUserService();
  const baseUrl = props.baseUrl || window.location.href;
  const tokenProvider = props.tokenProvider || userService;
  const [apiService, setApiService] = useState(
    () => new ApiService(baseUrl, tokenProvider)
  );
  useEffect(() => setApiService(new ApiService(baseUrl, tokenProvider)), [
    baseUrl,
    tokenProvider
  ]);

  return (
    <ApiServiceContext.Provider value={apiService}>
      {props.children}
    </ApiServiceContext.Provider>
  );
};

import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { LoadingLink, LoadingNavLink } from "../components/LoadingLink";

export interface TabsProps {
  block?: boolean;
}

export const Tabs: React.FC<TabsProps> = props => {
  const className = classNames("tab", props.block && "tab-block");
  return <div className={className}>{props.children}</div>;
};

export interface NavTabProps {
  to: string;
  text: string;
  exact?: boolean;
}

export const NavTab: React.FC<NavTabProps> = props => {
  return (
    <li className="tab-item">
      <LoadingNavLink
        to={props.to}
        activeClassName="active"
        exact={props.exact}
      >
        {props.text}
      </LoadingNavLink>
    </li>
  );
};

export const TabItem: React.FC<{}> = props => {
  return <li className="tab-item">{props.children}</li>;
};

export const TabAction: React.FC<{}> = props => {
  return <li className="tab-item tab-action">{props.children}</li>;
};

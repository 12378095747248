import { APICache } from "./APICache";
import { Contribution } from "../../models/Contribution";
import { ApiService } from "./ApiService";

interface DateFilters {
  start?: string | null;
  end?: string | null;
}

export class ContributionService {
  private listCache: ContributionListCache;

  constructor(api: ApiService) {
    this.listCache = new ContributionListCache(api);
  }

  getContributions(filters: DateFilters) {
    return this.listCache.get(filters);
  }
}

class ContributionListCache extends APICache<Contribution[], DateFilters> {
  getUrl(params: DateFilters) {
    const { start, end } = params;
    return [
      "/contributions",
      {
        ...(start ? { start } : {}),
        ...(end ? { end } : {})
      }
    ] as const;
  }
}

import React from "react";
import { Button } from "../spectre/Button";
import { getCognitoLogoutUrl } from "../utils/cognito";
import { useUserService } from "../utils/UserService";

export const LogoutButton: React.FC<{}> = React.memo(() => {
  const userService = useUserService();
  const handleOnClick = () => {
    userService.logout();
  };

  return (
    <Button type="button" onClick={handleOnClick} buttonStyle="link">
      Logout
    </Button>
  );
});

import React from "react";
import { Router } from "./routes/Router";
import { UserServiceProvider, useUserService } from "./utils/UserService";
import { Loading } from "./spectre/Loading";
import { BootError } from "./components/BootError";
import { ApiServiceProvider } from "./utils/ApiService";

export const App = () => (
  <UserServiceProvider>
    <ApiServiceProvider baseUrl="https://api.kbc-ministries.adharris.io">
      <React.Suspense fallback={<Loading large />}>
        <KBC />
      </React.Suspense>
    </ApiServiceProvider>
  </UserServiceProvider>
);

const KBC = () => {
  const service = useUserService();
  const bootError = service.suspendForBoot();
  console.log("result", bootError);
  return bootError ? <BootError error={bootError} /> : <Router />;
};

import React from "react";
import { useParams } from "react-router-dom";
import { useApiService } from "../utils/ApiService";

export const Member: React.FC<{}> = props => {
  const api = useApiService();
  const { id } = useParams();
  const member = api.members.getMember(id!);

  return (
    <div>
      <h1>
        {member.reportName} {member.lastName}
      </h1>
      <pre>{JSON.stringify(member, null, 2)}</pre>
    </div>
  );
};

import React from "react";

export interface CurrencyProps {
  value: number;
}

export const Currency: React.FC<CurrencyProps> = props => {
  const formatted = props.value.toLocaleString("en-US", {
    currency: "USD",
    style: "currency"
  });
  return <span>{formatted}</span>;
};

import React, { useState, useEffect, useRef } from "react";
import { InputGroup, Input } from "../spectre/Input";
import { Icon, ActionIcon } from "../spectre/Icon";
import { Button } from "../spectre/Button";

export interface BufferedSearchProps {
  value: string;
  onChange?: (value: string) => void;
  pending?: boolean;
  placeholder?: string;
  delay?: number;
  holdEmit?: boolean;
}

export const BufferedSearch: React.FC<BufferedSearchProps> = props => {
  const { onChange, pending } = props;
  const [state, setState] = useState(props.value);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (onChange && !pending) {
      onChange(state);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup>
        <Input
          placeholder={props.placeholder}
          type="search"
          value={state}
          onChange={e => setState(e.currentTarget.value)}
        />
        <Button
          buttonStyle="primary"
          className="input-group-btn"
          type="submit"
          loading={pending}
        >
          Search
        </Button>
      </InputGroup>
    </form>
  );
};

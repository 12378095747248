import { ApiService } from "./ApiService";

export abstract class CacheBase<T, K> {
  private cache: Map<string, T | Promise<void> | Error>;

  constructor() {
    this.cache = new Map();
  }

  abstract getKey(key: K): string;
  abstract load(key: K): Promise<T>;

  get(key: K): T {
    const stringKey = this.getKey(key);
    let value = this.cache.get(stringKey);

    if (!value) {
      value = this.load(key)
        .then(loadedValue => {
          this.cache.set(stringKey, loadedValue);
        })
        .catch(error => {
          this.cache.set(
            stringKey,
            error instanceof Error ? error : new Error(error)
          );
        });
      this.cache.set(stringKey, value);
    }

    if (value instanceof Promise || value instanceof Error) {
      throw value;
    }

    return value;
  }

  clear(key: K) {
    const stringKey = this.getKey(key);
    this.cache.delete(stringKey);
  }
}

export abstract class APICache<T, K> extends CacheBase<T, K> {
  private api: ApiService;

  constructor(api: ApiService) {
    super();
    this.api = api;
  }

  abstract getUrl(key: K): string | readonly [string, Record<string, string>];

  getKey(key: K) {
    const url = this.getUrl(key);

    if (typeof url === "string") {
      return url;
    } else {
      const params = new URLSearchParams(url[1]).toString();
      return `${url[0]}?${params}`;
    }
  }

  async load(key: K) {
    const url = this.getUrl(key);
    const result =
      typeof url === "string"
        ? await this.api.get(url)
        : await this.api.get(...url);

    return this.processResults(result);
  }

  protected processResults(results: any): T {
    return results;
  }
}

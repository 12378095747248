import React from "react";
import classnames from "classnames";

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type: "button" | "submit" | "reset" | undefined;
  buttonStyle?: "default" | "primary" | "link";
  color?: "success" | "error";
  buttonSize?: "small" | "medium" | "large";
  fullWidth?: boolean;
  active?: boolean;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = props => {
  const {
    buttonStyle,
    color,
    buttonSize,
    fullWidth,
    active,
    loading,
    className,
    ...rest
  } = props;
  const newClasses = classnames(className, "btn", {
    "btn-primary": buttonStyle === "primary",
    "btn-link": buttonStyle === "link",
    "btn-success": color === "success",
    "btn-error": color === "error",
    "btn-sm": buttonSize === "small",
    "btn-lg": buttonSize === "large",
    "btn-block": fullWidth,
    "btn-active": active,
    loading: loading
  });
  return (
    <button className={newClasses} {...rest}>
      {props.children}
    </button>
  );
};

export interface ButtonGroupProps {
  fullWidth?: boolean;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = props => {
  const className = classnames("btn-group", {
    "btn-group-block": props.fullWidth
  });
  return <div className={className}>{props.children}</div>;
};

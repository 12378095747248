import React, { useCallback, MouseEvent, useTransition } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import classNames from "classnames";

const DEFAULT_TIMEOUT = 2000;

export interface LoadingLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  to: string;
  timeoutMs?: number;
}

export const LoadingLink: React.FC<LoadingLinkProps> = props => {
  const { to, onClick, timeoutMs, target, ...passThrough } = props;
  const history = useHistory();
  const [startTransition, pending] = useTransition({
    timeoutMs: timeoutMs || DEFAULT_TIMEOUT
  });

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        onClick(event);
      }

      if (
        !event.defaultPrevented &&
        event.button === 0 &&
        (!target || target === "_self") &&
        !(event.metaKey || event.shiftKey || event.altKey || event.ctrlKey)
      ) {
        event.preventDefault();
        startTransition(() => {
          history.push(to);
        });
      }
    },

    [to, onClick, history, startTransition]
  );

  return (
    <a
      href={props.to}
      target={target}
      onClick={handleOnClick}
      {...passThrough}
      style={{ position: "relative" }}
    >
      {props.children}
      {pending && (
        <span className="loading" style={{ top: 0, right: "-1.5em" }}></span>
      )}
    </a>
  );
};

export interface LoadingNavLinkProps extends LoadingLinkProps {
  activeClassName: string;
  exact?: boolean;
}

export const LoadingNavLink: React.FC<LoadingNavLinkProps> = props => {
  const { className, exact, activeClassName, ...passThrough } = props;
  const match = useRouteMatch({ path: props.to, exact });
  const newClass = classNames(className, { [activeClassName]: match });
  return <LoadingLink className={newClass} {...passThrough} />;
};

import React, { useState, useRef, useMemo, useCallback } from "react";
import { Input, InputGroup } from "../spectre/Input";
import { Calendar, CalendarValue } from "./Calendar";

import styles from "./RangePicker.module.css";
import { FoldIn } from "./FoldIn";
import { useClickOutside } from "../hooks/useClickOutside";
import { useDateSearchParam, useURLSearchParams } from "../hooks/query-string";
import { isValid, parse, format } from "date-fns";
import { Button } from "../spectre/Button";
import { ActionIcon, Icon } from "../spectre/Icon";

export interface RangePickerProps {}

export const RangePicker: React.FC<RangePickerProps> = props => {
  const [query, setQuery, pending] = useURLSearchParams();
  const start = parseDateOrNull(query.get("start"));
  const end = parseDateOrNull(query.get("end"));

  const value = useMemo(
    () => (start && end ? { start, end } : start ? start : null),
    [start, end]
  );

  const [showCalendar, setShowCalendar] = useState(false);
  const handlerInputOnClick = () => setShowCalendar(show => !show);
  const [calendarRef, setCalendarRef] = useState<HTMLDivElement | null>(null);

  const handleOnChange = useCallback(
    (value: CalendarValue) => {
      if (!value) {
        setQuery({ start: null, end: null });
      } else if (value instanceof Date) {
        setQuery({ start: formatDateOrNull(value), end: null });
      } else {
        setQuery({
          start: formatDateOrNull(value.start),
          end: formatDateOrNull(value.end)
        });
      }
    },
    [setQuery]
  );

  const text = !value
    ? "All time"
    : value instanceof Date
    ? `Since ${format(value, "PPP")}`
    : `${format(value.start, "PPP")} - ${format(value.end, "PPP")}`;

  useClickOutside(
    calendarRef,
    () => {
      setShowCalendar(false);
    },
    [setShowCalendar]
  );

  return (
    <InputGroup className={styles.wrapper}>
      <Input readOnly={true} value={text} onClick={handlerInputOnClick} />
      <FoldIn show={showCalendar} className={styles.calendar}>
        <Calendar
          value={value}
          onChange={handleOnChange}
          ref={setCalendarRef}
        />
      </FoldIn>

      {value && (
        <Button
          buttonStyle="default"
          className="input-group-btn"
          type="button"
          onClick={() => handleOnChange(null)}
        >
          <Icon icon={ActionIcon.Cross} />
        </Button>
      )}
      <Button
        buttonStyle="primary"
        className="input-group-btn"
        type="submit"
        loading={pending}
      >
        Search
      </Button>
    </InputGroup>
  );
};

function parseDateOrNull(value: string | null) {
  if (!value) return null;
  const date = parse(value, "yyyy-MM-dd", new Date());
  return isValid(date) ? date : null;
}

function formatDateOrNull(value: Date | null) {
  if (!value) return value;
  return isValid(value) ? format(value, "yyyy-MM-dd") : null;
}
